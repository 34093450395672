import http from '@/axios'
import AuthService from './AuthService';
import { IDropdown } from '@/interfaces/BaseInterface';

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString();

class DropdownData implements IDropdown {
    getCompanies() {
        return http.get(`dropdown/fetch-companies`);
    }

    getPackages() {
        return http.get(`dropdown/fetch-packages?${TOKEN_PARAM_STRING}`);
    }

    getRiders() {
        return http.get(`dropdown/fetch-riders?${TOKEN_PARAM_STRING}`);
    }
    
    getPatients() {
        return http.get(`dropdown/fetch-patients?${TOKEN_PARAM_STRING}`);
    }

}

export default new DropdownData();
